import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import Paragraph from '../atoms/typography/Paragraph';
import Spacer from '../atoms/utility/Spacer';
import Button from '../atoms/inputs/Button';
import useFetchCommunities from '../../utils/hooks/useFetchCommunities';

const EventsListBlogV2 = ({ cityName, categoryPage, eventsData }) => {
  const [filteredEventsData, setFilteredEventsData] = React.useState([]);
  const { communities: allCommunities } = useFetchCommunities();
  const location = useLocation();
  const categories = [
    'Downtown',
    'Free',
    'Live Music',
    'Daytime',
    'Collaboration',
  ];

  function findCommunityByName(communityName, communities) {
    for (let i = 0; i < communities.length; i++) {
      if (communities[i].name === communityName) {
        return {
          image_url: communities[i].image_url,
          name: communities[i].name,
        };
      }
    }
    return null; // or some default value
  }

  const usePathIncludesCategoryPage = (category) => {
    return location.pathname
      .toLowerCase()
      .replaceAll(' ', '-')
      .includes(category.toLowerCase().replaceAll(' ', '-'));
  };

  React.useEffect(() => {
    setFilteredEventsData(
      eventsData.filter((event) =>
        event.category
          .map((c) => c.toLowerCase())
          .includes(categoryPage.toLowerCase())
      )
    );
  }, [eventsData, categoryPage]);

  const formatDate = (date) => {
    if (!date) {
      return '';
    }

    const options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleString('en-US', options);
  };

  const categoryImages = [
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1689175664/flockx-webapp/v0.9.4%20-%20Manual%20AI%20Blog%20Post%20V2/downtown_s8ujfs.png',
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1689175664/flockx-webapp/v0.9.4%20-%20Manual%20AI%20Blog%20Post%20V2/free_hoz2vn.png',
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1689175664/flockx-webapp/v0.9.4%20-%20Manual%20AI%20Blog%20Post%20V2/live-music_g5gcjo.png',
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1689175664/flockx-webapp/v0.9.4%20-%20Manual%20AI%20Blog%20Post%20V2/day-time_otduti.png',
    'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1689175663/flockx-webapp/v0.9.4%20-%20Manual%20AI%20Blog%20Post%20V2/web-app_m1q9jz.png',
  ];

  return (
    <div className="p-4 text-grey">
      <div className="text-center mb-12 lg:mb-20 max-w-lg mx-auto md:max-w-4xl">
        <h1 className="text-4xl md:text-7xl flex flex-col gap-4 md:gap-6">
          <span className="block">What's going on in</span>
          <span className="block font-bold">{cityName} this July?</span>
        </h1>
      </div>
      <div className=" grid grid-cols-2 gap-4 md:gap-0 md:flex justify-around mb-4 lg:mb-20">
        {categories.map((category, i) => (
          <div key={i} className="text-center w-full">
            {category === 'Collaboration' ? (
              <a
                href="http://app.flockx.io/?utm_source=website&utm_medium=events_list&utm_campaign=4th_july_2023_events"
                className="flex-col justify-center items-center hidden md:flex"
              >
                <img
                  src={categoryImages[i]}
                  alt=""
                  className="w-16 h-16 lg:w-[150px] lg:h-[150px] rounded-full mb-2 lg:mb-8"
                />
                <h3 className="text-xs md:text-xl lg:text-3xl">{category}</h3>
                {usePathIncludesCategoryPage(category) && (
                  <hr className="bg-default h-1 w-full my-4 max-w-[200px]" />
                )}
              </a>
            ) : (
              <Link
                to={`/events/${cityName
                  .toLowerCase()
                  .replaceAll(' ', '-')}/${category
                  .toLowerCase()
                  .replace(' ', '-')}`}
                className="flex flex-col justify-center items-center"
              >
                <img
                  src={categoryImages[i]}
                  alt=""
                  className="w-16 h-16 lg:w-[150px] lg:h-[150px] rounded-full mb-2 lg:mb-8"
                />
                <h3 className="text-xs md:text-xl lg:text-3xl">{category}</h3>
                {usePathIncludesCategoryPage(category) && (
                  <hr className="bg-default h-1 w-full my-4 max-w-[200px]" />
                )}
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        {filteredEventsData.map(
          (
            {
              title,
              start_date,
              end_date,
              location,
              websiteURL,
              imageURL,
              price,
              info,
              communities,
            },
            i
          ) => {
            const formattedStartDate = formatDate(start_date);
            const formattedEndDate = formatDate(end_date);

            return (
              <div key={i} className="md:flex rounded p-4">
                <div className="md:h-full flex flex-col items-center justify-center">
                  <img
                    src={imageURL}
                    alt={title}
                    className="mb-2 rounded max-w-[320px]"
                  />
                </div>
                <div className="flex flex-col py-4 md:p-4 lg:p-12 gap-4">
                  <h3 className="text-xl lg:text-4xl mb-2 font-bold">
                    {title}
                  </h3>
                  <div className="text-base lg:text-xl flex flex-col gap-2 md:gap-4">
                    <p className="mb-2">
                      <span className="font-bold">Date:</span>{' '}
                      {formattedStartDate} {formattedEndDate ? '-' : ''}{' '}
                      {formattedEndDate}
                    </p>

                    <p className="mb-2">
                      {' '}
                      <span className="font-bold">Price:</span> {price}
                    </p>
                    <p className="mb-2">
                      <span className="font-bold">Location: </span> {location}
                    </p>
                    <a href={websiteURL} target="_blank">
                      <span className="font-bold">Website: </span>
                      <span className="text-highlight">Learn More</span>
                    </a>
                    <p className="mb-2">
                      <span className="font-bold">Info:</span> {info}
                    </p>
                    <div>
                      <p className="font-bold mb-2"> Community at flockx</p>
                      <ul className="grid grid-cols-2 md:inline-flex gap-4">
                        {communities.map((communityName, index) => {
                          const communityMatch = findCommunityByName(
                            communityName,
                            allCommunities
                          );

                          if (!communityMatch) return;

                          return (
                            <li
                              key={index}
                              className="text-xs md:text-base border border-default rounded-lg p-2 flex gap-2 items-center justify-start"
                            >
                              <img
                                src={communityMatch.image_url}
                                alt={communityMatch.name}
                                className="h-8 w-8"
                              />
                              {communityMatch.name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <Spacer sizeY={1} />
      <div>
        <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4 text-center">
          Your <span className="font-bold">Activities</span>, Your{' '}
          <span className="font-bold">Story</span>
        </h3>
        <Paragraph
          paragraphText={`Share Your Adventures with a Global Community and become a Leader on the flockx Web App today!`}
          className="max-w-xl mx-auto mb-4 md:mb-8 text-grey-100 text-center"
        />
        <div className="mx-auto flex items-center justify-center w-[min-content]">
          <a href="https://app.flockx.io" target="_blank" className="w-full">
            <Button label="Learn More" className="w-[200px]" />
          </a>
        </div>
      </div>
      <Spacer sizeY={1} />
    </div>
  );
};

export default EventsListBlogV2;
